<template>
  <div class="flex-col profile">
    <v-card-title>
      <h1>Branding</h1>
    </v-card-title>
    <v-divider style="width: 100%" />
    <div class="flex-row">
      <div class="flex-col option">
        <h2>Light Logo</h2>
        <span>
          Light logos use light text so they show up well on dark backgrounds.
        </span>
        <div class="light photo">
          <img v-if="light" :src="light" />
          <input
            :id="`${id}-company-checkout-page-input-upload-file`"
            accept="image/*"
            class="option-select"
            type="file"
            @change="(evt) => uploadFile(evt, 'light')"
          />
        </div>
        <div class="flex-col bottom">
          <span>Drag and drop your logo above or select a file below.</span>
          <div class="button-cont">
            <div class="input-cont">
              <v-btn
                :id="`${id}-company-checkout-page-button-select-file`"
                class="btn-secondaryaction"
              >
                Select File
              </v-btn>
              <input
                :id="`${id}-company-checkout-page-input-upload-file-select`"
                type="file"
                class="option-select"
                @change="(evt) => uploadFile(evt, 'light')"
              />
            </div>
            <v-btn
              :id="`${id}-company-checkout-page-button-upload-file-select`"
              class="btn-primaryaction"
              :disabled="disabledlight"
              :loading="loadinglight"
              @click="(evt) => submit('light')"
            >
              Upload
            </v-btn>
          </div>
        </div>
      </div>
      <div class="flex-col spehss" />
      <div class="flex-col option">
        <h2>Dark Logo</h2>
        <span>
          Dark logos use dark text so they show up well on light backgrounds.
        </span>
        <div class="dark photo">
          <img v-if="dark" :src="dark" />
          <input
            :id="`${id}-company-checkout-page-input-upload-file-dark`"
            accept="image/*"
            class="option-select"
            type="file"
            @change="(evt) => uploadFile(evt, 'dark')"
          />
        </div>
        <div class="flex-col bottom">
          <span>Drag and drop your logo above or select a file below.</span>
          <div class="button-cont">
            <div class="input-cont">
              <v-btn outline solo>Select File</v-btn>
              <input
                :id="`${id}-company-checkout-page-input-upload-file-dark-select`"
                type="file"
                accept="image/*"
                class="option-select"
                @change="(evt) => uploadFile(evt, 'dark')"
              />
            </div>
            <v-btn
              :id="`${id}-company-checkout-page-button-upload-file-dark-select`"
              class="btn-primaryaction"
              :disabled="disableddark"
              :loading="loadingdark"
              @click="(evt) => submit('dark')"
            >
              Upload
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authComputed } from '@/state/helpers'
import checkoutPages from '@/services/checkoutPages'
import { filter } from '@/utils/filter'
import { baseUrl } from '@/utils/env'

export default {
  props: {
    mode: {
      type: String,
      default: 'view',
    },
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      disabledlight: true,
      disableddark: true,
      loadingdark: false,
      loadinglight: false,
      light: null,
      dark: null,
      lightFile: null,
      darkFile: null,
      display: false,
      snackType: false,
      msg: '',
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const brandingFilter = filter()
    const parentFilter = brandingFilter.createParent('and')
    this.companyId = this.currentUser?.companyId

    brandingFilter.add(parentFilter, {
      column: {
        _t_id: 'czz',
        prop: 'companyCheckoutPageId',
        filterType: 'eq',
      },
      value: this.id,
    })

    const data = await checkoutPages
      .getCheckoutPages({
        pageSize: -1,
        page: 1,
        filters: brandingFilter.asQueryParams(),
      })
      .then((data) => data.data.resultList)

    this.light = 'https://' + baseUrl(null) + data[0]?.lightLogoUrl
    this.dark = 'https://' + baseUrl(null) + data[0]?.darkLogoUrl
  },
  methods: {
    displayMsg(msg, type) {
      this.display = !this.display
      this.snackType = type
      this.msg = msg
    },
    uploadFile(evt, type) {
      const file = evt.target.files[0]
      this[type] = URL.createObjectURL(file)
      this[`${type}File`] = file

      this[`disabled${type}`] = false
    },
    async submit(type) {
      this[`loading${type}`] = true
      try {
        await checkoutPages.postCheckoutBranding({
          payload: this[`${type}File`],
          companyId: this.companyId,
          companyCheckoutId: this.id,
          type,
        })

        this.$store.dispatch('app/showAlert', {
          message: `Successfully updated ${type} branding photo!`,
        })
      } catch (e) {
        this.$store.dispatch('app/showAlert', {
          message: `Error in updating ${type} branding photo!`,
          type: 'error',
        })
      }
      this[`loading${type}`] = false
    },
  },
}
</script>

<style lang="scss" scoped>
h2,
span {
  margin: 10px 0 10px 0;
  text-align: center;
}

img {
  max-width: 100%;
  max-height: 100%;
}

span {
  color: $blue-gray;
}

.logo-input {
  position: absolute;
  width: 100%;
  opacity: 0;
}

.button-cont {
  display: flex;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.dark {
  background-color: $blue-light;
}

.light {
  background-color: $primary;
}

.profile {
  position: relative;
  padding: 24px;
  margin: 0 24px 48px 24px;
  background: $white;
  border-radius: 15px;
}

.photo {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  padding: 30px;
}

.option {
  flex-basis: 45%;
}

.spehss {
  width: 10px;
}

.option-select {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.input-cont {
  position: relative;
}

.toast {
  position: absolute;
  bottom: 2%;
}

@media only screen and (max-width: 1200px) {
  .profile {
    margin: 0 16px 0 24px;
  }
}

::v-deep .v-card__title {
  padding: 16px 16px 16px 0;
}
</style>
